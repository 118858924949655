// components
import { DefaultNotFoundPage, EnterpriseNotFoundPage } from "./components";

// utilities
import { useFeatureToggle } from "../../providers/FeatureToggleProvider";

// constants
import { FeatureNames } from "../../providers/FeatureToggleProvider";

export const NotFoundPage: React.FC = () => {
  const isNewFlowEnabled = useFeatureToggle(FeatureNames.PROFILE_PAGE_NEW_FLOW);

  return isNewFlowEnabled ? (
    <EnterpriseNotFoundPage />
  ) : (
    <DefaultNotFoundPage />
  );
};
