// libraries
import { useContext, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useTranslate } from "@dcl/tools";

// components
import { ErrorPage } from "../../../../components";
import { LocalizationContext } from "../../../../providers/LocalizationProvider";
import { PageNotFoundIcon } from "../../../../common/icons";

// utilities
import { getBasePath } from "../../../../utilities";
import { pageViewed } from "../../../../analytics";
import { useNavigation } from "../../../../hooks";

// constants
import { CORRECT_BASE_PATH } from "../../NotFoundPageConstants";

// types
import { Dispatch } from "../../../../redux";

export const DefaultNotFoundPage: React.FC = () => {
  const dispatch = useDispatch<Dispatch>();
  const translate = useTranslate();
  const { locale } = useContext(LocalizationContext);
  const { navigate, paths, redirect } = useNavigation();

  const basePath = getBasePath(locale);
  const isCorrectPath = CORRECT_BASE_PATH.test(window.location.pathname);

  useEffect(() => {
    dispatch(pageViewed(paths.catchAll));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ErrorPage
      buttonProps={{
        children: translate("NotFoundPage.SignInButtonText"),
        onClick: () =>
          // Redirect to index page if the base path is in url (e.g. http://test.com/en-us/profile/test),
          // otherwise redirect to base path /en-us/profile (e.g. http://test.com/test)
          isCorrectPath ? redirect(paths.profile) : navigate(basePath),
      }}
      heading={translate("NotFoundPage.Heading")}
      icon={<PageNotFoundIcon />}
      subheading={translate("NotFoundPage.Subheading")}
    />
  );
};
